// TODO move this to theme
const sizes = {
  // Used in hamburger menu and left and right package. In hamburger menu, no image is displayed on mobile/tablet
  small: {
    small_desktop: 419,
    large_desktop: 536
  },
  // Used in right side of left-and-right-package.
  medium: {
    tablet: 470, // In fact, it's 517 at 885px width, but on an Ipad (768px width) it's 391, so let's honor the Ipad.
    small_desktop: 688,
    large_desktop: 952
  },
  // Used in:
  // * hamburger menu. No image is displayed on mobile/tablet
  // * cont-pack-* at home, which are full width so they use a smaller image.
  // * full width images on articles (in this case, we avoid calling this
  //   function because 'large' in an article means always 'full width')
  // TODO define different sizes for the above cases.
  large: {
    // TODO disabled because in ContentPackage6.jsx there is a data.contentPackageHeader that shows
    // a full-width image. Do not know if this happens in other places, but before refactoring this,
    // the most plausible solution is to just disable this size. The problem is that "large" is used
    // in a totally different context, and usually through a Card component that doesn't have any
    // prop to define a key for getMaxWidthsBySize(). I think the solution could be to have a prop
    // for size (which affects styling, so it could be renamed to styling) and another one for width
    // in pixels.
    small_desktop: 703
  },
  // Used in horizontal blocks with three elements.
  third: {
    tablet: 290,
    small_desktop: 397,
    large_desktop: 533
  },
  // Used in right side of left-and-right-package.
  xs: {
    tablet: 132,
    small_desktop: 300,
    large_desktop: 421
  },
  // Used in video search (/video et al) and search results.
  search: {
    tablet: 344,
    small_desktop: 350,
    large_desktop: 555
  },
  // Used in article's main column.
  art_main_col_small: {
    tablet: 472,
    small_desktop: 315,
    large_desktop: 315
  },
  art_main_col_medium: {
    small_desktop: 789,
    large_desktop: 760
  },
  expandedGallery: {
    small_desktop: 733,
    large_desktop: 1190 /* it's 1221 but don't use the bigger one */
  },
  homepageVideoPreview: {
    small_desktop: 893,
    large_desktop: 1136
  },
  homepageVideoThumbnail: {
    tablet: 351,
    small_desktop: 298,
    large_desktop: 352
  },
  transporterBox: {
    tablet: 280,
    small_desktop: 280,
    large_desktop: 280
  }
};

export const getMaxWidthsBySize = size => {
  if (!size) {
    return {};
  }
  if (!sizes[size]) {
    throw new Error(`Size "${size}" not known on getMaxWidthsBySize.`);
  }
  return sizes[size];
};
