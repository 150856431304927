import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Image } from '../Image';
import { getMaxWidthsBySize } from '../../domain/utils/getMaxWidthsBySize';
import { facePaint } from '../../domain/theme/utils/facePaint';
import {
  ngartImg,
  ngartImgCntr,
  ngartImgCont,
  ngartImgContCopy,
  ngartImgSize
} from '../Styles/articleImage';
import { ContentTakeOver } from '../responsive/organisms/ContentTakeOver';
import { PhotographersLink } from '../responsive/atoms/PhotographersLink';

const styles = {
  // Keep 'content-visibility: auto' commented so ContentTakeOver can be displayed at full-width,
  // instead of being displayed inside the image div.
  // content-visibility: auto;
  contentVisibility: css`
    ${facePaint({
      // Keep these values updated to avoid anchor links going down and up when clicking on them.
      // If these values are mismatched with actual height values, and a user clicks on an anchor
      // link, the browser scrolls to a point where that anchor is not present, so it goes up to
      // the point where it really is. This happens when clicking on TOC links, which make the
      // navigation menu appear, hiding the anchor target.
      'contain-intrinsic-size': ['0 472px', '0 629px', '0 486px', '0 507px']
    })};
  `
};

export const ArticleImage = ({
  imageEntity,
  size,
  aspect,
  isAboveTheFold,
  preload,
  className
}) => {
  if (!imageEntity) {
    return <div />;
  }

  const maxWidthSize =
    size === 'small' || size === 'medium' ? `art_main_col_${size}` : size;

  return (
    <div
      className={`ngart-img--${size} ${className}`}
      css={[styles.contentVisibility, ngartImg, ngartImgSize[size]]}
    >
      <ContentTakeOver imageEntity={imageEntity}>
        <div className="ngart-img__cntr" css={ngartImgCntr}>
          <Image
            entity={imageEntity}
            aspect={aspect}
            maxWidths={
              size !== 'large' ? getMaxWidthsBySize(maxWidthSize) : null
            }
            addSchema
            loading={isAboveTheFold ? 'eager' : 'lazy'}
            preload={preload}
          />
        </div>
      </ContentTakeOver>

      <div className="ngart-img__cont" css={ngartImgCont}>
        {imageEntity.caption && imageEntity.caption !== '' && (
          <div
            className="ngart-img__cont__copy"
            css={ngartImgContCopy}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: imageEntity.caption
            }}
          />
        )}
        <PhotographersLink photographers={imageEntity.photographers} />
      </div>
    </div>
  );
};

ArticleImage.defaultProps = {
  aspect: undefined,
  isAboveTheFold: false,
  preload: false,
  className: ''
};

ArticleImage.propTypes = {
  imageEntity: PropTypes.objectOf(PropTypes.any).isRequired,
  size: PropTypes.string.isRequired,
  aspect: PropTypes.string,
  isAboveTheFold: PropTypes.bool,
  preload: PropTypes.bool,
  className: PropTypes.string
};

export default ArticleImage;
